import { useState } from "react";
import Button from "../../../components/Button";



const comparison = [
    {
        compare: "Other Online Schools",
        ROWS: [
            "Pricing",
            "Social & Development Opportunities",
            "Flexibility",
            "Future Readiness",
            "Qualification",
            "Personal Support",
            "Learning pace"
          ],
          us: [
            "Top international education, at very competitive price for local market",
            "Focus on mindset development and emerging technology education",
            "Highly flexible and adaptive. Personal learning journey. ",
            "Focus on developing skills for the 4th Industrial Revolution and future focused",
            "Accredited UK high school certificate. Passport to Global Education. Learning Portfolio to showcase best work",
            "Personal learning journey for each student. ",
            "Study when and how you want. You determine the pace. If you understand, you move quicker. If you need more time, then take it. ",         
          ],
          Competition: [
            "Standard international prices",
            "Often limited to PSHE or minimal focus on holistic development",
            "Rigid. Lessons tailored to the middle of the class ",
            "Very limited with some robotics and coding classes if any. ",
            "UK/USA high school diploma",
            "Limited to some interaction or not personalised and individualised",
            "Often rigid rules and deadlines to keep. ",
            
            
          ]
    },
    {
        compare: "Traditional Schools",
        ROWS: [
            "Education",
            "Social & Development Opportunities",
            "Flexibility",
            "Future Readiness",
            "Qualification",
            "Personal Support",
            "Learning pace",
          ],
          us: [
            "Modern, accredited UK curriculum for international students",
            "Focus on mindset development and emerging technology education",
            "Highly flexible and adaptive. Personal learning journey. ",
            "Focus on developing skills for the 4th Industrial Revolution and future focused",
            "Accredited UK high school certificate. Passport to global education",
            "1:1 support with dedicated success coach",
            "Study when and how you want. You determine the pace. If you understand, you move quicker. If you need more time, then take it. ",


          
          ],
          Competition: [
            "GAPS or local curriculum. May be outdated.",
            "Often limited to Life Orientation classes only",
            "Rigid. Lessons tailored to the middle of the class ",
            "Very limited with some robotics and coding classes if any.",
            "Matric certificate. May not be as competitive as A-Level Certificate",
            "Limited to some interaction with a form teacher",
            "Follow the pace of the middle of the class. ",
          ]
    },
]

const Comparison = () => {
  const [visibleItems, setVisibleItems] = useState(3);
  const [selectedComparison, setSelectedcomparison] = useState(0);

  const loadMore = () => {
    setVisibleItems((prev) => Math.min(prev + 5, comparison[selectedComparison].ROWS.length));
  };

  return (
    <section
      id="comparison"
      className="py-12 lg:py-24 scroll-mt-12 relative mx-auto"
    >
      <div className="relative z-10">
        <div className="flex container items-center justify-center flex-col gap-5 mx-auto">
          <span className="text-2xl text-sunny_orange font-abolition uppercase text-center">
            iHubs
          </span>
          <h2 className="text-center text-3xl lg:text-4xl font-bold title max-w-[40ch] mx-auto">
          The Difference between iHubs and the others
          </h2>
        </div>
        <div className="mt-6 flex justify-center">
          <fieldset aria-label="Comparison options">
            <div
              className="grid grid-cols-2 gap-x-1 rounded-full bg-b p-1 text-center font-semibold leading-5 text-white"
            >
              {comparison.map((option, index) => (
                <div
                  key={index}
                  value={option.compare}
                  className={`cursor-pointer rounded-full px-3 text-sm md:text-md py-2 relative z-20 transition-all duration-300 ${selectedComparison === index ? "bg-white/25" : "bg-b"}`}
                  onClick={() => { setSelectedcomparison(index) }}
                >
                  {option.compare}
                </div>
              ))}
                
            </div>
          </fieldset>
        </div>
        <div className="mx-auto pt-10 container relative overflow-hidden">
          {/* Desktop View */}
          <div className="hidden lg:grid grid-cols-5 gap-4 md:gap-8 w-full lg:max-w-[1300px] mx-auto">
            <div className="flex flex-col gap-5">
              <div className="p-5 h-[72px]" />
              {comparison[selectedComparison].ROWS.map((item, index) => (
                <div
                  key={`row-${index}`}
                  className="p-5 bg-b rounded-lg flex items-center justify-center h-[100px]" // Set a consistent height
                >
                  <p className="text-sm lg:text-lg text-center text-white">{item}</p>
                </div>
              ))}
            </div>

            <div
              className="rounded-xl flex flex-col gap-5 px-6 col-span-2 bg-gradient-to-b from-b via-b/10 to-white "
            >
              <div className="p-5 h-[72px]">
                <p className="text-center mt-4 text-xl text-white font-medium">
                  iHubs
                </p>
              </div>
              {comparison[selectedComparison].us.map((item, index) => (
                <div
                  key={`campaign-${index}`}
                  className="p-5 bg-[#F6FAF6] rounded-lg flex items-center justify-center h-[100px]" // Consistent height
                >
                  <p className="text-sm lg:text-lg text-center">{item}</p>
                </div>
              ))}
            </div>

            <div className="flex flex-col gap-5 col-span-2">
              <div className="p-5 h-[72px]">
                <p className="text-center text-xl mt-4 opacity-65 font-medium">
                {comparison[selectedComparison].compare}
                </p>
              </div>
              {comparison[selectedComparison].Competition.map((item, index) => (
                <div
                  key={`traditional-${index}`}
                  className="p-5 bg-[#F6FAF6] rounded-lg flex items-center justify-center h-[100px]" // Consistent height
                >
                  <p className="text-sm lg:text-lg text-center">{item}</p>
                </div>
              ))}
            </div>
          </div>


          {/* Mobile View */}
          <div className="lg:hidden flex flex-col gap-4 mx-3">
            {comparison[selectedComparison].ROWS.slice(0, visibleItems).map((row, index) => (
              <div
                key={`mobile-${index}`}
                className="space-y-2 rounded-[8px] p-4 bg-b text-base"
              >
                <h6 className="text-md font-semibold ml-2 opacity-80 uppercase tracking-wider text-white">
                  {row}
                </h6>
                <div className="space-y-4">
                  <div className="p-4 rounded-[8px] bg-[#F6FAF6]">
                    <span className="block text-md uppercase text-b font-semibold mb-1">
                      iHubs
                    </span>
                    <p className="text-md text-gray-800">{comparison[selectedComparison].us[index]}</p>
                  </div>
                  <div className="p-4 rounded-[8px] bg-[#F9F9F9]">
                    <span className="block text-md uppercase text-gray-500 font-semibold mb-1">
                    {comparison[selectedComparison].compare}
                    </span>
                    <p className="text-md text-gray-800">
                      {comparison[selectedComparison].Competition[index]}
                    </p>
                  </div>
                </div>
              </div>
            ))}

            {visibleItems < comparison[selectedComparison].ROWS.length && (
              <div className="flex justify-center pt-4">
                <button onClick={loadMore} className="bg-black m-[3px] text-white flex gap-3 rounded-full px-3 py-2 items-center hover:bg-b hover:m-[3px] hover:text-white transition-all duration-300 ease-in-out">
                  Load more
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Comparison;
