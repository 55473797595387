import { Card, CardContent, CardDescription, CardTitle } from "./card";


function cn() {
    return Array.prototype.slice.call(arguments).filter(Boolean).join(" ");
}

const USP = ({ CARD_STATIC_DATA, paragraphs, title }) => {
  return (
    <section className="container py-5 mx-auto">
      <h2 className="text-center px-2">
            {title ?? "What is iHubs?"}
      </h2>
      {paragraphs.map((item, index) => (
        <p key={index} className="prose mx-auto text-center">{item}</p>
      ))}
      <div className="py-10 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
        {CARD_STATIC_DATA.map((item) => (
          <Card
            className={cn(
              "border-none shadow-none pt-6 rounded-[12px]",
              item.id !== 53 ? "bg-[#F6FAF6]" : "bg-b"
            )}
            key={item.id}
          >
            <CardContent className="space-y-4 p-0">
              <div>{item.icon}</div>
              <CardTitle
                className={cn(
                  "font-dm text-2xl",
                  item.id !== 53 ? "text-[#023220]" : "text-[#fff]"
                )}
              >
                {item.title}
              </CardTitle>
              <CardDescription
                className={cn(
                  "",
                  item.id !== 53 ? "text-[#585B6F]" : "text-[#fff]"
                )}
              >
                {item.subTitle}
              </CardDescription>
            </CardContent>
          </Card>
        ))}
      </div>
    </section>
  );
};

export default USP;
