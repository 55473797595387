import { Link } from "react-router-dom";
import Button from "../../../components/Button";
import { PopupButton } from "react-calendly";

export default function Hero() {
    return (
    <section style={{margin: 0}} className="relative lg:h-screen">
      <div className="mx-auto max-w-7xl  px-1 lg:px-0">
        <div className="relative z-10 pt-14 lg:w-full lg:max-w-2xl">
        <svg
          className="absolute inset-y-0 right-8 hidden h-full w-80 translate-x-1/2 transform fill-white lg:block"
          viewBox="0 0 100 100"
          preserveAspectRatio="none"
          aria-hidden="true"
        >
          <polygon points="0,0 90,0 50,100 0,100" />
        </svg>

        <div className="relative px-6 flex items-center justify-center lg:pr-0  lg:h-screen py-24">
          <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-xl relative my-auto">
          <h2 className="font-bold tracking-tight text-gray-700 ">An innovative hybrid school</h2>
          <h1 className="font-bold tracking-tight text-b leading-tight">
          Combining UK education with social development.
          </h1>
          <p className="mt-6 text-lg leading-7 text-gray-600 ">
          iHubs offers the international version of the UK education certificates (international GCSE & international A-Levels) completely online.
          <ul className="list-disc ml-4 mt-2 space-y-1.5">
            <li>Study whenever you want.</li>
            <li>Personal success coaches and expert teachers</li>
            <li>Real-world education about VR/AR, Robotics, Adulting 101, Art Classes, and more.</li>
            <li>Enrichment activities facilitated in learning centres worldwide for social development.</li>
          </ul>
          </p>
          <div className="mt-10 flex items-center gap-x-6">
            <Button
            text="Get started"
            />
            <Link to="#" className="text-sm font-semibold leading-6 text-gray-900">
            <PopupButton
                url="https://api.leadconnectorhq.com/widget/group/bFKqFvEUkobN5sl5DUxT"
                rootElement={document.getElementById("root")}
                text="Talk with us →"
              />
            </Link>
          </div>
          </div>
        </div>
        </div>
      </div>
      <div className="bg-gray-50 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
        <img
        className="aspect-[3/2] object-cover lg:aspect-auto lg:h-full lg:w-full"
        src="https://images.unsplash.com/photo-1521737711867-e3b97375f902?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1587&q=80"
        alt=""
        />
      </div>
      </section>
    )
}