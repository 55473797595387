import Hero from "./components/Hero";
import Locations from "./components/Locations";
import Mission from "./components/Mission";
import Pilars from "./components/Pilars";
import Scroll from "./components/Scroll";

export default function Service() {
    return (
        <div>
            <Hero />
            <Pilars />
            {/* <Locations /> */}
        </div>
    )
}         