import { useState, useEffect } from 'react';
import * as React from 'react';
import PropTypes from 'prop-types';
import { Box, styled } from '@mui/system';
import { useSpring, animated } from '@react-spring/web';
import { Link } from 'react-router-dom';
import Button from './Button';
import useIsMobile from '../hooks/useIsMobile';
const BackdropUnstyled = React.forwardRef((props, ref) => {
  const { open, ...other } = props;
  return <Fade ref={ref} in={open} {...other} />;
});

BackdropUnstyled.propTypes = {
  open: PropTypes.bool.isRequired,
};

const StyledModal = styled(Box)`
  position: fixed;
  z-index: 1300;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Backdrop = styled(BackdropUnstyled)`
  z-index: -1;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-tap-highlight-color: transparent;
`;

const Fade = React.forwardRef(function Fade(props, ref) {
  const { in: open, children = <div />, onEnter, onExited, ...other } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter(null, true);
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited(null, true);
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {children}
    </animated.div>
  );
});

Fade.propTypes = {
  children: PropTypes.element.isRequired,
  in: PropTypes.bool,
  onEnter: PropTypes.func,
  onExited: PropTypes.func,
};

export default function Navbar() {
  const [modal1IsOpen, setModal1IsOpen] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [toggled, setToggled] = useState(false);

  const handleScroll = () => {
    const scrollTop = window.scrollY;
    if (scrollTop > 200) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  function apply() {
    setModal1IsOpen(true);
  }
  function toggleDropdownCurriculum() {
    var a = document.querySelector('#dropdownNavbarCurriculum')
    a.classList.contains('hidden') ? a.classList.remove('hidden') : a.classList.add('hidden');
  }
  function toggleDropdownService() {
    var a = document.querySelector('#dropdownNavbarService')
    a.classList.contains('hidden') ? a.classList.remove('hidden') : a.classList.add('hidden');
  }

  function toggleDropdownAdmissions() {
    var a = document.querySelector('#dropdownNavbarAdmission')
    a.classList.contains('hidden') ? a.classList.remove('hidden') : a.classList.add('hidden');
  }

  function toggleDropdownStudent() {
    var a = document.querySelector('#dropdownNavbarStudent')
    a.classList.contains('hidden') ? a.classList.remove('hidden') : a.classList.add('hidden');
  }
  const toggleScrollLock = (lock) => {
    if (lock) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
  };
  var isMobile = useIsMobile(1024)
  function toggleHamburger() {
    var a = document.querySelector('#hamburger-menu')
    var b = document.querySelector('#menu03')
    a.classList.contains('hidden') ? a.classList.remove('hidden') : a.classList.add('hidden');
    b.classList.contains('active') ? b.classList.remove('active') : b.classList.add('active');
    console.log(isMobile)
    if(toggled === false && isMobile) { 
      setToggled(true)
      toggleScrollLock(true)
    } else if (toggled === true && isMobile) {
      setToggled(false)
      toggleScrollLock(false)
    }
    
  }

  return (
    <nav className={`py-2.5  fixed  z-[9999] top-0 left-0 px-5  w-full transition-colors duration-500 ${isVisible ? "bg-b text-white backdrop-blur-lg bg-opacity-60 backdrop-opacity-40" : "lg:bg-transparent bg-white w-auto"}}`}>
      <div className={`container flex flex-wrap mx-auto transition-all duration-500 justify-between md:justify-normal gap-10 blur-none`}>
        <Link to="/" >
          <img className={`h-20 ${isVisible ? " grayscale contrast-200 invert" : ""}`} src={"https://firebasestorage.googleapis.com/v0/b/ihubss.appspot.com/o/Home%2FColor%20logo%20-%20no%20background.png?alt=media&token=1b998f84-2e5c-4995-ada7-8f5eb9c5fa5a"} alt="" />
        </Link>
        <button id='menu03' onClick={toggleHamburger} className="menu-trigger relative w-[25px] h-[22px] bg-none border-none appearance-none cursor-pointer transition-all duration-500 box-border my-auto inline-flex items-center text-sm rounded-lg lg:hidden ">
          <span className={`${isVisible ? "bg-white" : "bg-b"} inline-block transition-all duration-500 box-border absolute left-0 w-full h-[3px] rounded top-0`}></span>
          <span className={`${isVisible ? "bg-white" : "bg-b"} inline-block transition-all duration-500 box-border absolute left-0 w-full h-[3px] rounded top-[9.5px] `}></span>
          <span className={`${isVisible ? "bg-white" : "bg-b"} inline-block transition-all duration-500 box-border absolute left-0 w-full h-[3px] rounded bottom-0`}></span>
        </button>
        <div id='hamburger-menu' className={`hidden w-full lg:block lg:w-auto ${isVisible ? "text-white" : ""}`}>
          <ul className={`flex items-center flex-col py-4 px-1 mt-4 border rounded-lg lg:flex-row lg:space-x-6 lg:mt-0  lg:border-0 border-dl ${isVisible ? "text-lg lg:font-bold" : "lg:text-sm text-lg lg:font-bold"}`}>
            <Link to="/">
              <li onClick={toggleHamburger} className="block py-2 pl-3 pr-4 rounded lg:border-0 lg:p-0 bg-clip-text hover:text-transparent hover:bg-black">
                Home
              </li>
            </Link>
            <Link to="/about">
              <li onClick={toggleHamburger} className="block py-2 pl-3 pr-4 rounded lg:border-0 lg:p-0 bg-clip-text hover:text-transparent hover:bg-black ">
                About Us
              </li>
            </Link>
            <li className="relative cursor-pointer z-40" onMouseEnter={toggleDropdownService} onMouseLeave={toggleDropdownService}>
              <p id="dropdownNavbarLink" className="delay-1 flex items-center justify-between w-max py-2 pl-3 pr-4 rounded lg:border-0 lg:p-0  bg-clip-text hover:text-transparent hover:bg-black ">What we do</p>
              <div id="dropdownNavbarService" className="absolute hidden font-normal divide-y rounded shadow w-max bg-white ">
                <div className='absolute h-[50vh] top-0 left-0 right-0 customgradient'></div>
                <div className="relative">
                  <ul className={`py-1 text-sm ${isVisible ? "text-black " : ""}`}>
                    <Link to="/whatwedo">
                      <li onClick={toggleHamburger} className="block px-4 py-2 hover:bg-gray-50 hover:text-b">
                        General Overview 
                      </li>
                    </Link>
                    <Link to="/academics">
                      <li onClick={toggleHamburger} className="block px-4 py-2 hover:bg-gray-50 hover:text-b">
                        Academics
                      </li>
                    </Link>
                    <Link to="/personal-development">
                      <li onClick={toggleHamburger} className="block px-4 py-2 hover:bg-gray-50 hover:text-b">
                        Personal Development
                      </li>
                    </Link>
                  </ul>
                </div>
              </div>
            </li>
             {/* <li className="relative cursor-pointer z-40" onMouseEnter={toggleDropdownCurriculum} onMouseLeave={toggleDropdownCurriculum}>
              <p id="dropdownNavbarLink" className="delay-1 flex items-center justify-between w-max py-2 pl-3 pr-4 rounded lg:border-0 lg:p-0  bg-clip-text hover:text-transparent hover:bg-black ">Locations</p>
              <div id="dropdownNavbarCurriculum" className="absolute hidden font-normal divide-y overflow-y-auto max-h-[50vh] rounded shadow w-max bg-white divide-gray-600">
                <div className='absolute h-[50vh] top-0 left-0 right-0 customgradient'></div>
                <div className="relative">
                  <ul className={`py-1 text-sm ${isVisible ? "text-black " : ""}`}>
                    <p className="block pr-4 pl-2 font-bold pt-2 cursor-default">Africa:</p>
                    <Link to="/hermanus">
                      <li onClick={toggleHamburger} className="block px-4 py-2 hover:bg-gray-50 hover:text-b">
                        Hermanus 
                      </li>
                    </Link>
                    <Link to="/johannesburg">
                      <li onClick={toggleHamburger} className="block px-4 py-2 hover:bg-gray-50 hover:text-b">
                        Johannesburg (Coming Soon)
                      </li>
                    </Link>
                    <Link to="/mossel-baai">
                      <li onClick={toggleHamburger} className="block px-4 py-2 hover:bg-gray-50 hover:text-b">
                        Mossel Baai (Coming Soon)
                      </li>
                    </Link>
                    <Link to="/windhoek">
                      <li onClick={toggleHamburger} className="block px-4 py-2 hover:bg-gray-50 hover:text-b">
                        Windhoek (Coming Soon)
                      </li>
                    </Link>
                    <Link to="/pretoria">
                      <li onClick={toggleHamburger} className="block px-4 py-2 hover:bg-gray-50 hover:text-b">
                        Pretoria (Coming Soon)
                      </li>
                    </Link>
                    <Link to="/abuja">
                      <li onClick={toggleHamburger} className="block px-4 py-2 hover:bg-gray-50 hover:text-b">
                        Abuja (Coming Soon)
                      </li>
                    </Link>
                    <p className="block pr-4 pl-2 font-bold pt-2 cursor-default">Europe:</p>
                    <Link to="/lisbon">
                      <li onClick={toggleHamburger} className="block px-4 py-2 hover:bg-gray-50 hover:text-b">
                        Lisbon (Coming Soon)
                      </li>
                    </Link>
                    <Link to="/albufeira">
                      <li onClick={toggleHamburger} className="block px-4 py-2 hover:bg-gray-50 hover:text-b">
                        Albufeira (Coming Soon)
                      </li>
                    </Link>
                    <p className="block pr-4 pl-2 font-bold pt-2 cursor-default">Asia:</p>
                    <Link to="/kuala-lumpur">
                      <li onClick={toggleHamburger} className="block px-4 py-2 hover:bg-gray-50 hover:text-b">
                        Kuala Lumpur (Coming Soon)
                      </li>
                    </Link>
                  </ul>
                </div>
              </div>
            </li> */}
            <Link to="/hermanus">
            <li onClick={toggleHamburger} className="block py-2 pl-3 pr-4 rounded lg:border-0 lg:p-0 bg-clip-text hover:text-transparent hover:bg-black">
            Hermanus 
              </li>
            </Link>
            <Link to="/contact">
              <li onClick={toggleHamburger} className="block py-2 pl-3 pr-4 rounded lg:border-0 lg:p-0 bg-clip-text hover:text-transparent hover:bg-black">
                Contact
              </li>
            </Link>
            <Button onClick={toggleHamburger} text="Apply now"></Button>
          </ul>
        </div>
      </div>
    </nav>
  );
}
