import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { PopupButton } from "react-calendly";
import { faClose } from "@fortawesome/free-solid-svg-icons"; 

export default function ContactWidget() {
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleExpand = () => setIsExpanded(!isExpanded);

    return (
        <div className="fixed right-5 bottom-5 z-40">
            {/* Waving Hand Button */}
            <button
                onClick={toggleExpand}
                className={`text-4xl cursor-pointer  p-4 rounded-full ${
                    isExpanded ? "text-b absolute" : "animate-wave"
                }`}
            >
                {isExpanded ? <FontAwesomeIcon icon={faClose} className="mt-[1.12rem]" size="2xs" /> : <span className="text-6xl">👋</span>}
            </button>

            {/* Expandable Contact Options */}
            <div
                className={`${
                    isExpanded ? "max-h-96 opacity-100 w-[20rem]" : "max-h-0 opacity-0"
                } transition-all duration-300 overflow-hidden bg-white rounded-lg shadow-lg mt-4`}
            >
                {isExpanded && (
                    <div className="p-4">
                        <h2 className="text-lg font-semibold mb-4 ml-6">Get in Touch</h2>

                        {/* WhatsApp Contact Button */}
                        <a
                            href="https://wa.me/+27601092525" // Replace with your WhatsApp Business number
                            target="_blank"
                            rel="noopener noreferrer"
                            className="flex items-center justify-center bg-green-500 text-white p-3 rounded-lg mb-4 hover:bg-green-600"
                        >
                            <FontAwesomeIcon icon={faWhatsapp} className="mr-2" />
                            Contact us on WhatsApp
                        </a>

                        {/* Book a Call Button */}
                        <PopupButton
                            url="https://api.leadconnectorhq.com/widget/group/bFKqFvEUkobN5sl5DUxT"
                            rootElement={document.getElementById("root")}
                            text="Next step: Schedule a call"
                            className="w-full bg-blue-600 text-white p-3 rounded-lg hover:bg-blue-700"
                        />
                    </div>
                )}
            </div>
        </div>
    );
}
