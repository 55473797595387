import { Link } from "react-router-dom";
import Button from "../../../components/Button";
import { PopupButton } from "react-calendly";

export default function Hero() {

    
    return (
        <section className="relative isolate z-0 overflow-hidden bg-gradient-to-b from-indigo-100/20 pt-14">
            <div
                className="absolute inset-y-0 right-1/2 -z-10 -mr-96 w-[200%] origin-top-right skew-x-[-30deg] bg-white shadow-xl shadow-indigo-600/10 ring-1 ring-indigo-50 sm:-mr-80 lg:-mr-96"
                aria-hidden="true"
            />
            <div className="flex gap-10 xl:flex-row flex-col-reverse mx-auto px-6 py-32 sm:py-40 sm:pb-24 lg:px-8 container">
                <img className='lg:w-1/2 w-full aspect-[3/2] object-cover object-right md:max-w-3xl rounded-xl h-max my-auto' width={'42rem'} alt="studying student" src={"https://firebasestorage.googleapis.com/v0/b/ihubss.appspot.com/o/student-photo-4.webp?alt=media&token=8932634b-0062-47f5-bbfa-021962132721"}></img>
                <div className="mx-auto xl:mr-0 xl:ml-auto my-auto">
                    <h1 className="max-w-4xl text-4xl font-bold tracking-tight sm:text-6xl lg:col-span-2 xl:col-auto">
                    We provide a prestigious UK education 100% online 
                    </h1>
                    
                    <div className="mt-6 max-w-3xl lg:mt-0 xl:col-end-1 xl:row-start-1">
                        <p className="mt-6 text-lg leading-8 text-gray-600">
                        iHubs offers the international version of the UK education certificates (international GCSE & international A-Levels) completely online. Everyone can benefit from an international education. We combine this with real-world education about VR/AR, Robotics, Adulting 101, Art Classes, and so much more! We call these enrichment activities. All enrichment activities will be facilitated in our learning centres worldwide, where students can engage in their social development in a safe and secure environment. 
                        </p>
                    </div>

                    <div className="mt-10 flex items-center gap-x-6">
                        <Button
                            text="Get started"
                        />
                        <PopupButton 
                        url="https://api.leadconnectorhq.com/widget/group/bFKqFvEUkobN5sl5DUxT"
                        rootElement={document.getElementById("root")}
                        className="text-sm font-semibold leading-6 text-gray-900"
                        text="Talk with us →"
                        >        
                            
                        </PopupButton>
                    </div>
                </div>
            </div>
            
            <div className="absolute inset-x-0 bottom-0 -z-10 h-24 bg-gradient-to-t from-white sm:h-32" />
        </section>

    )
}