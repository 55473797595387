import { PopupButton } from "react-calendly";
import Button from "../../../components/Button";

export default function Hero() {

    
    return (
        <section className="relative isolate z-0 overflow-hidden bg-gradient-to-b from-indigo-100/20 pt-14">
            <div
                className="absolute inset-y-0 right-1/2 -z-10 -mr-96 w-[200%] origin-top-right skew-x-[-30deg] bg-white shadow-xl shadow-indigo-600/10 ring-1 ring-indigo-50 sm:-mr-80 lg:-mr-96"
                aria-hidden="true"
            />
            <div className="flex gap-10 xl:flex-row flex-col-reverse mx-auto px-6 pt-32 sm:pt-40 sm:pb-24 lg:px-8 container">
            <img className='lg:w-1/2 w-full aspect-[3/2] object-cover object-right md:max-w-3xl rounded-xl h-max my-auto' width={'42rem'} alt="studying student" src={"https://firebasestorage.googleapis.com/v0/b/ihubss.appspot.com/o/student-photo-3.webp?alt=media&token=ec701ff4-8dba-48c5-9efe-0a97e8d8a116"}></img>

                <div className="mx-auto xl:mr-0 xl:ml-auto my-auto">
                  <h2 className="max-w-4xl text-2xl font-bold tracking-tight text-gray-900 sm:text-4xl lg:col-span-2 xl:col-auto">
                  Alongside the UK Curriculum
                  </h2>
                  <h1 className="max-w-4xl text-4xl font-bold tracking-tight text-b sm:text-6xl lg:col-span-2 xl:col-auto">
                  We offer enrichment activities to develop the whole self. 
                  </h1>
                    
                    <div className="mt-6 max-w-3xl lg:mt-0 xl:col-end-1 xl:row-start-1">
                      <p className="mt-6 text-lg leading-8 text-gray-600">
                      At iHubs, we offer a range of enriching activities designed to help personal development skills. Our programs include VR/AR experiences, Robotics, Adulting 101, Art Classes, and more. These activities are aimed at developing essential life skills and promoting holistic growth in a safe and secure environment. At our physical facilities we provide a safe and secure environment to mitiage negative socialisation that standard schools experience.
                      </p>
                    </div>
                    {/*  <ul className="text-lg lg:leading-8 text-gray-600 lg:space-y-4 list-disc pl-6">
                            <li>World-class online education with internationally recognised qualifications</li>
                            <li>Flexible learning to progress at your own pace</li>
                            <li>Physical centres worldwide for socialisation and personal development</li>
                            <li>Supportive environment free from traditional school-related social pressures</li>
                        </ul> */}
                    <div className="mt-10 flex items-center gap-x-6">
                        <Button
                            text="Get started"
                        />
                        <PopupButton 
                        url="https://api.leadconnectorhq.com/widget/group/bFKqFvEUkobN5sl5DUxT"
                        rootElement={document.getElementById("root")}
                        className="text-sm font-semibold leading-6 text-gray-900"
                        text="Talk with us →"
                        >        
                            
                        </PopupButton>
                    </div>
                </div>
            </div>
            
            <div className="absolute inset-x-0 bottom-0 -z-10 h-24 bg-gradient-to-t from-white sm:h-32" />
        </section>

    )
}