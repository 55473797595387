import logo from './logo.svg';
import './App.css';
import React, { lazy, Suspense, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import './output.css'
import './input.css'
import Home from './pages/home/page';
import FacilityDetail from './pages/facilities/FacilityDetail';
import About from './pages/about/page';
import Service from './pages/service/page';
import FAQ from './components/FAQ';
import Location from './pages/location/page';
import Facility from './pages/facilities/page';
import Contact from './pages/contact/page';
import useScrollToTop from './components/useScrollToTop';
import SouthAfrica from './pages/sa/SA';
import SouthAfricaPopup from './components/SA-Popup';
import Apply from './pages/apply/apply';
import CallInline from './components/Call-Inline';
import Callpopup from './components/Callpopup';
import Privacy from './pages/footer/privacy';
import TermsOfUse from './pages/footer/terms';
import ReactGA from "react-ga4";
import Academics from './pages/academics/page';
import Personaldevelopment from './pages/personaldevelopment/page';
import Steps from './pages/steps/page';
import ReactPixel from 'react-facebook-pixel';

function ScrollToTop() {
  useScrollToTop();

  return null; // This component doesn't render anything
}

function App() {

  ReactGA.initialize("G-QNWPC1CKY3");

  const options = {
    autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
    debug: false, // enable logs
  };

  ReactPixel.init('1893968341392203', options);
  ReactPixel.pageView(); // For tracking page view
  
 
  return (
    <div>
      <Router>
      <ScrollToTop />
        <Suspense fallback={ <div className='h-screen w-screen bg-dd pt-40 text-7xl text-center mx-auto justify-center text-white flex items-center'>loading...</div>} >
          <div className='flex flex-col justify-between min-h-screen overflow-x-clip mx-2'>
              <Navbar />
              <Callpopup />
              <Routes>
                <Route path='/' element={ <Home />}/>
                <Route path="/about" element={<About />} />
                <Route path="/whatwedo" element={<Service />} />
                <Route path="/academics" element={<Academics />} />
                <Route path="/personal-development" element={<Personaldevelopment />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/learn" element={<Steps />} />
                <Route path="/personal-development/:facility" element={<FacilityDetail />} />
                <Route path="/:location" element={<Location />} />
                {/* <Route path="/south-africa" element={<SouthAfrica />} /> */}
                <Route path="/apply" element={<Apply />} />
                <Route path="/privacy" element={<Privacy />} />
                <Route path="/terms-of-use" element={<TermsOfUse />} />
              </Routes>
              <CallInline maxHeight={"800px"} autoscroll/>
              <section className="h-[65rem] ">
                <h2 className=' mx-auto text-center'>Or contact us through this form:</h2>
                <iframe
                src="https://api.leadconnectorhq.com/widget/form/9PXK4pgcWX06GP7bjknm"
                style={{width:"100%", height:"100%", margin:"auto", border: "none", borderRadius: "3px"}}
                id="inline-TMDgVfWMUoViRUz1KJri" 
                data-layout="{'id':'INLINE'}"
                data-trigger-type="alwaysShow"
                data-trigger-value=""
                data-activation-type="alwaysActivated"
                data-activation-value=""
                data-deactivation-type="neverDeactivate"
                data-deactivation-value=""
                data-form-name="Enquiry"
                data-height="783"
                data-layout-iframe-id="inline-TMDgVfWMUoViRUz1KJri"
                data-form-id="TMDgVfWMUoViRUz1KJri"
                title="Enquiry"
                    >
              </iframe>
              <script src="https://link.msgsndr.com/js/form_embed.js"></script>
            </section>

              <FAQ />
              <Footer />
          </div>
        </Suspense>
      </Router>
     
    </div>
  );
}

export default App;
