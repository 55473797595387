import React, { useState } from 'react';

const Schedule = ({ activities }) => {
  const [showAllDays, setShowAllDays] = useState(false);
  const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];
  const mobileDays = showAllDays ? days : days.slice(0, 1);

  return (
    <section className="text-center container mx-auto p-6">
      <h2 className="">Weekly Personal Development Schedule</h2>
      <p className='prose mx-auto'>iHubs members select four sessions they wish to attend each week. We encourage members to select as broad interests as they can.</p>
      <div className="grid grid-cols-1 md:grid-cols-5 gap-4 mt-4">
        {/* Time Slots for Each Day */}
        {/* Show all days on desktop, filtered days on mobile */}
        {(window.innerWidth >= 768 ? days : mobileDays).map((day) => (
          <div key={day} className="flex flex-col space-y-4">
            <div className="text-center text-xl font-semibold text-gray-600">
              {day}
            </div>
            {activities.map((activity, index) => (
              <div
                key={index}
                className={`p-4 rounded-lg shadow-lg text-center ${
                  activity[day] ? 'bg-gradient-to-r from-b/70 to-b text-white' : 'bg-gray-100 text-black'
                }`}
              >
                <div className="text-sm font-medium">{activity.time}</div>
                <div className="text-lg font-bold">{activity[day] || 'No Activity'}</div>
              </div>
            ))}
          </div>
        ))}
      </div>
      
      {/* Load More Button - Only visible on mobile when not all days are shown */}
      <div className="md:hidden mt-6 ">
        {!showAllDays && (
          <button
            onClick={() => setShowAllDays(true)}
            className="bg-black m-[3px] mx-auto hover:mx-auto text-white flex gap-3 rounded-full px-3 py-2 items-center hover:bg-b hover:m-[3px] hover:text-white transition-all duration-300 ease-in-out"
          >
            Load More Days
          </button>
        )}
      </div>
    </section>
  );
};

export default Schedule;
