import Pricing from "../location/components/Pricing";
import About from "./components/about";
import Comparison from "./components/Comparison";
import Exams from "./components/exams";
import Fascilities from "./components/fascilities";
import Hero from "./components/hero";
import Social from "./components/social";
import USP from "./components/usp";

export default function Home() {
    return (
        <div>
            <Hero />
            <div className="max-w-[100rem] mx-auto px-5 md:px-0">
                <USP />
                <Comparison />
            </div>
        </div>
    )
} 