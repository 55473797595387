import { InlineWidget } from "react-calendly";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";


export default function CallInline({maxHeight, notitle, autoscroll, nowhatsapp}) {
    return (
        <section className="">
            {notitle ?? <h2 className="text-center">Want guidance? Schedule a meeting with us!</h2>}
         {nowhatsapp ??   <div className="space-x-4 mb-4 max-w-xs mx-auto">
                        <a
                            href="https://wa.me/+27601092525" // replace with your WhatsApp Business number
                            target="_blank"
                            rel="noopener noreferrer"
                            className="flex items-center justify-center bg-green-500 text-white text-lg font-medium p-3 rounded-md mb-4 w-full hover:bg-green-600 overflow"
                        >
                            <FontAwesomeIcon icon={faWhatsapp} className="mr-2" />
                            Contact on WhatsApp
                        </a>
            </div>}
            <div className="prose mx-auto">
            <iframe src="https://api.leadconnectorhq.com/widget/booking/2lwBXRteYNcRrEAMdKdc" title="Schedule a call" style={{width:"100%",border:"none",height:maxHeight ?? "1000px",overflow:"visible"}} scrolling={autoscroll ? "yes" : "no"} id="bFKqFvEUkobN5sl5DUxT_1742295051724"></iframe><br/><script src="https://link.msgsndr.com/js/form_embed.js" type="text/javascript"></script>
            </div>
        </section>
    )
}