import { useState } from 'react'
import { CheckIcon, ChevronDoubleDownIcon } from '@heroicons/react/20/solid'
import { FaToggleOn } from 'react-icons/fa';
import { ChevronDown } from 'react-feather';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const pricing = {
  frequencies: [
  { value: 'monthly', label: 'Monthly', priceSuffix: '/month' },
  { value: 'annually', label: 'Annually', priceSuffix: '/year' },
  ],
  tiers: [
    {
      name: 'Lower Secondary',
      id: 'tier-ms',
      href: '/apply',
      price: { monthly: 'R5 000', annually: 'R48 000' },
      description: "Year 7-8-9 can be completed within 2 years - every student can complete it in 2 years with us. This will be comparative to grade 8 and 9 in the SA system.",
      features: ["Mathematics", "English/Afrikaans", "Geography", "Science", "French/German"],
      mostPopular: false,
      ms: true,
      },
  {
  name: 'Bachelor of Commerce',
  id: 'tier-commerce',
  href: '/apply',
  price: { monthly: 'R5 000', annually: 'R48 000' },
  description: 'Designed for those pursuing a Bachelor of Commerce, this route provides a strong foundation in business subjects, preparing you for university-level commerce programmes.',
  features: ["Business", "Economics", "Accounting", "Mathematics", "English/Afrikaans", "French/German"],
  mostPopular: false,
  },
  {
  name: 'Bachelor of Science',
  id: 'tier-science',
  href: '/apply',
  price: { monthly: 'R5 000', annually: 'R48 000' },
  description: 'Ideal for students aiming for a Bachelor of Science, this route provides a solid background in sciences to prepare for further studies.',
  features: ["Biology", "Chemistry", "Physics", "Mathematics", "English/Afrikaans", "French/German"],
  mostPopular: true,
  },
  {
  name: 'Bachelor of Arts',
  id: 'tier-arts',
  href: '/apply',
  price: { monthly: 'R5 000', annually: 'R48 000' },
  description: 'Perfect for those interested in a Bachelor of Arts, this route covers humanities subjects to prepare for arts and social sciences.',
  features: ["History", "Geography", "Environmental Management", "English/Afrikaans", "French/German"],
  mostPopular: false,
  },
  {
  name: 'Bachelor of Medicine',
  id: 'tier-medicine',
  href: '/apply',
  price: { monthly: 'R6 000', annually: 'R55 000' },
  description: 'Tailored for aspiring medical students, this comprehensive route prepares you for a Bachelor of Medicine with essential subjects.',
  features: ["Biology", "Chemistry", "Mathematics", "English", "Afrikaans"],
  mostPopular: false,
  },
  {
  name: 'Bachelor of Computer Science & IT',
  id: 'tier-cs-it',
  href: '/apply',
  price: { monthly: 'R6 000', annually: 'R55 000' },
  description: 'Ideal for future IT professionals, this route provides an introduction to computing and technology, preparing you for a Bachelor in Computer Science & IT.',
  features: ["Mathematics", "Information Technology", "Computer Science", "English/Afrikaans", "French/German", "ICT"],
  mostPopular: false,
  },
  ],
  };

export default function Pricing({location}) {
  const [frequency, setFrequency] = useState(pricing.frequencies[0])
  const [showAll, setShowAll] = useState(false)
  const [showSubject, setshowSubject] = useState(0)

  const displayedTiersMobile = showAll ? pricing.tiers : pricing.tiers.slice(0, 2)

  return (
    <section className={`bg-white py-24 sm:py-32 ${location.includes("Coming Soon") === true ? "hidden" : ''}`}>
      <div className="mx-auto  px-6 lg:px-8">
        <div className="mx-auto max-w-4xl text-center">
          <h3 className="text-base font-semibold text-b lg:text-2xl">Pricing</h3>
          <h2 className='lg:text-5xl'>Academic Routes for Lower secondary, international GCSE and iA-Levels</h2>
        </div>
        <div className='prose max-w-[85ch] mx-auto'>
        <p className="mx-auto max-w-[85ch] text-center text-lg text-gray-600">
        Students can select an academic route suited to their needs and talents. The education model is highly adaptive and flexible and shatters traditional boundaries and restrictions. If a young person has a particular strength in a subject area, they can move quicker through the curriculum, studying at advanced levels for their age. Similarly, if a young person needs a little more time, this is easily achieved. Our team of dedicated study coaches and iHubs staff ensure that support is always available. 
        </p>
        <p className="mx-auto max-w-[85ch] text-center text-lg text-gray-600">
          Depending on when young people join iHubs, it is possible to finish high school at 17 and gain one year to start university earlier, or take a gap year to travel. 
        </p>
        </div>

        <div className="mt-6 flex justify-center">
          <fieldset aria-label="Payment frequency">
            <div
              value={frequency}
              onChange={setFrequency}
              className="grid grid-cols-2 gap-x-1 rounded-full bg-b p-1 text-center text-md font-semibold leading-5 text-white"
            >
              {pricing.frequencies.map((option) => (
                <div
                  key={option.value}
                  value={option}
                  className={`cursor-pointer rounded-full px-3 py-2 relative z-20 transition-all duration-300 ${frequency === option ? "bg-white/25" : "bg-b"}`}
                  onClick={() => { setFrequency(option) }}
                >
                  {option.label}
                </div>
              ))}
            </div>
          </fieldset>
        </div>

        <div className="md:hidden mx-auto mt-10 flex flex-wrap justify-center gap-8 flex-1">
          {displayedTiersMobile.map((tier, index) => (
            <div
              key={tier.id}
              className={classNames(
                tier.mostPopular ? 'bg-b ring-2 ring-indigo-500' : 'ring-1 ring-white/10',
                'rounded-3xl p-8 xl:p-10 bg-b flex gap-10 flex-col md:flex-row sm:min-w-[600px] flex-1'
              )}
            >
              <div>
                <div className="flex items-center justify-between gap-x-4 relative">
                  <h2 id={tier.id} className="text-3xl font-semibold leading-8 text-white text-start">
                    {tier.name}
                  </h2>
                  {tier.mostPopular ? (
                    <p className="rounded-full bg-indigo-500 px-2.5 py-1 text-xs font-semibold leading-5 text-white whitespace-nowrap ">
                      Most popular
                    </p>
                  ) : null}
                </div>
                <p className="mt-4 text-lg leading-6 text-gray-300 text-start max-w-lg">{tier.description}</p>
                <p className="mt-6 flex items-baseline gap-x-1">
                  <span className="text-5xl font-bold tracking-tight text-white">{tier.price[frequency.value]}</span>
                  <span className="text-md font-semibold leading-6 text-gray-300">{frequency.priceSuffix}</span>
                </p>
              </div>
              <div className='text-md font-semibold leading-6 text-white mt-auto transition-all duration-300 ease-in-out' onClick={() => setshowSubject(index + 1 === showSubject ? 0 : index + 1)}>
                <div className='flex items-center'>
                  <ChevronDown className={`h-7 w-7 transition-all duration-300 ease-in-out ${index + 1 === showSubject ? "" : "-rotate-90"}`} />
                  <p className='cursor-pointer'>{index + 1 === showSubject ? "Hide" : "Show "} Subjects:</p>
                </div>
                <div className={`transition-all duration-300 ease-in-out overflow-hidden ${
                  index + 1 === showSubject ? 'max-h-[500px]' : 'max-h-0'
                }`}>
                  <p className='text-white font-bold'>{tier.ms ? "Lower Secondary Subjects" : "international GCSE & iA-Levels Subjects"}:</p>
                  <ul role="list" className="mt-4 space-y-3 text-md leading-6 text-gray-300">
                    {tier.features.map((feature) => (
                      <li key={feature} className="flex gap-x-3">
                        <CheckIcon aria-hidden="true" className="h-6 w-5 flex-none text-white" />
                        {feature}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="hidden mx-auto mt-10 md:grid-cols-2 md:grid md:auto-rows-auto lg:grid-cols-3 justify-center gap-8 flex-1 transition-all duration-300 ease-in-out">
          {pricing.tiers.map((tier, index) => (
            <div
              key={tier.id}
              className={classNames(
                tier.mostPopular ? 'bg-b ring-2 ring-indigo-500' : 'ring-1 ring-white/10',
                'rounded-3xl p-8 xl:p-10 bg-b flex gap-10 flex-col  sm:min-w-[350px] flex-1 transition-all duration-300 ease-in-out',
                showSubject === 0 ? '' : 'self-start'
              )}
            >
              <div>
                <div className="flex items-center justify-between gap-x-4 relative">
                  <h2 id={tier.id} className="text-3xl font-semibold leading-8 text-white text-start">
                    {tier.name}
                  </h2>
                  {tier.mostPopular ? (
                    <p className="rounded-full absolute -top-6 -right-6 bg-indigo-500 px-2.5 py-1 text-xs font-semibold leading-5 text-white whitespace-nowrap w-max">
                      Most popular
                    </p>
                  ) : null}
                </div>
                <p className="mt-4 text-lg leading-6 text-gray-300 text-start max-w-lg">{tier.description}</p>
                <p className="mt-6 flex items-baseline gap-x-1">
                  <span className="text-5xl font-bold tracking-tight text-white">{tier.price[frequency.value]}</span>
                  <span className="text-md font-semibold leading-6 text-gray-300">{frequency.priceSuffix}</span>
                </p>
              </div>
              <div className='text-white mt-auto transition-all duration-300 ease-in-out' onClick={() => setshowSubject(index + 1 === showSubject ? 0 : index + 1)}>
                <div className='flex items-center'>
                  <ChevronDown className={`h-7 w-7 transition-all duration-300 ease-in-out ${index + 1 === showSubject ? "" : "-rotate-90"}`} />
                  <p className='cursor-pointer'>{index + 1 === showSubject ? "Hide" : "Show "} Subjects:</p>
                </div>
                <div className={`transition-all duration-300 ease-in-out overflow-hidden ${
                  index + 1 === showSubject ? 'max-h-[500px]' : 'max-h-0'
                }`}>
                  <p className='text-white font-bold'>{tier.ms ? "Lower Secondary Subjects" : "international GCSE & iA-Levels Subjects"}:</p>
                  <ul role="list" className="mt-4 space-y-3 text-md leading-6 text-gray-300">
                    {tier.features.map((feature) => (
                      <li key={feature} className="flex gap-x-3">
                        <CheckIcon aria-hidden="true" className="h-6 w-5 flex-none text-white" />
                        {feature}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="md:hidden mt-6 ">
        {!showAll && (
          <button
            onClick={() => setShowAll(true)}
            className="bg-black m-[3px] mx-auto hover:mx-auto text-white flex gap-3 rounded-full px-3 py-2 items-center hover:bg-b hover:m-[3px] hover:text-white transition-all duration-300 ease-in-out"
          >
            Load More Routes
          </button>
        )}
      </div>
      </div>
    </section>
  )
}
