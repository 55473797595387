import CallInline from "../../components/Call-Inline";
import Comparison from "../home/components/Comparison";
import USP from "../home/components/usp";
import Schedule from "../location/components/Schedule";

export default function Steps() {
    const activities = [
        { time: '10h00 - 11h30', Monday: 'Virtual Reality', Tuesday: 'Free Art', Wednesday: 'Robotics and Coding', Thursday: 'Free Art', Friday: 'Digital Creativity' },
        { time: '12h00 - 13h30', Monday: 'Free Art', Tuesday: '', Wednesday: 'Mid-week Hike/Trip', Thursday: 'Social Builder', Friday: '' },
        { time: '14h00 - 15h30', Monday: 'Digital Creativity', Tuesday: 'Virtual Reality', Wednesday: '', Thursday: 'Virtual Reality', Friday: 'Career Explorer' },
        { time: '16h00 - 17h30', Monday: 'Social Builder', Tuesday: 'Adulting 101', Wednesday: '', Thursday: 'Robotics and Coding', Friday: '' },
        { time: '18h00 - 19h30', Monday: 'Adulting 101', Tuesday: 'Digital Creativity', Wednesday: '', Thursday: 'Career Explorer', Friday: '' },
      ];
    return (
        <section>
            <div className="prose max-w-[95ch] mx-auto prose-headings:text-b text-center py-24">
                <h1 className="max-w-[95ch]">iHubs provides online UK education <i className="font-normal">(international GCSE & iA-Levels)</i> and local hub for socialisation personal development <i className="font-normal">(Robotics, Adulting 101, Art, ...)</i></h1>
                <p className="prose mx-auto lg:text-lg">We provide maximum flexibility with asynchronous studying, recorded or live lesson options. We have multiple levels of support with 1:1 Success coach meetings and teacher sessions.</p>
                <p className="text-b font-semibold lg:text-2xl">Would like to learn more? Please follow step 1 & 2.</p>
                <h2 className="font-black">Step 1: <span className="text-gray-900 tracking-tight font-normal">Schedule a call with us</span></h2>
                <CallInline maxHeight={"750px"} autoscroll={true} notitle nowhatsapp />
                <h2 className="font-black">Step 2: <span className="text-gray-900 tracking-tight font-normal">Accept meeting invitation in calendar (check your spam email!)</span></h2>
            </div>
                <USP />
                <Schedule activities={activities} />
                <Comparison />
        </section>
    )
}