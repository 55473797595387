import { Link } from "react-router-dom";

export default function Pilars() {
    return (
      <section className="py-16">
        <div className="max-w-6xl mx-auto text-center">
          <h2 className="text-4xl font-bold mb-8">Our Two Pillars of Learning</h2>
          <p className="text-lg text-gray-600 mb-12">
            We combine Academics with Social & Development Opportunities to create a holistic learning experience.
          </p>
          <div className="grid md:grid-cols-2 gap-10">
            {/* Academics */}
            <div className="bg-b p-8 rounded-2xl shadow-lg flex flex-col justify-between text-white relative">
                <div className="flex flex-col items-center">
                    <h3 className="text-2xl font-semibold mb-4">1. Online Academics</h3>
                    <p className="text-gray-100 text-start mb-4">
                        Delivered online, following the UK curriculum, to ensure a world-class education from anywhere.
                    </p>
                    <ul className="text-gray-100 text-left list-disc list-inside">
                        <li><strong>Success Coaches:</strong> Personalised guidance to help students set goals and track progress.</li>
                        <li><strong>Remote Learning:</strong> Interactive virtual classrooms and digital resources.</li>
                        <li><strong>1:1 Teacher Support:</strong> Individualised instruction tailored to each student’s needs.</li>
                        <li><strong>Flexibility:</strong> Self-paced learning that adapts to students’ schedules.</li>
                    </ul>
                </div>
                <div>
                    <Link to="/academics" className="mt-8 text-white font-semibold border-b border-white hover:border-transparent hover:text-gray-300 transition duration-300">More on academics</Link>
                </div>
            </div>
            {/* Personal Development */}
            <div className="bg-b text-white p-8 rounded-2xl shadow-lg flex flex-col justify-between items-center">
                <div className="flex flex-col items-center">
                    <h3 className="text-2xl font-semibold mb-4">2. Social & Development Opportunities</h3>
                    <p className="text-gray-100 text-start mb-4">
                        Conducted through our global physical learning centres, focusing on real-world skills, activities, and lessons.
                    </p>
                    <ul className="text-gray-100 text-left list-disc list-inside">
                    <li><strong>VR and AR Rooms:</strong> Immersive environments for interactive learning experiences.</li>
                    <li><strong>Robotics:</strong> Hands-on experience with robotics and automation technologies.</li>
                    <li><strong>Adulting 101:</strong> Practical life skills education covering finance, communication, and independence.</li>
                    <li><strong>Digital Recording Studios:</strong> Spaces for audio and video production.</li>
                    <li><strong>Art Studios:</strong> Dedicated areas for artistic expression and creativity.</li>
                    <li>...</li>
                    </ul>
                </div>
                <div>
                    <Link to="/personal-development" className="mt-8 text-white font-semibold border-b border-white hover:border-transparent hover:text-gray-300 transition duration-300">More on Social & Development Opportunities</Link>
                </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
  